<!--
 * @Description: 车行道停车管理系统 数据看板 收入分析 operationsManagement
 * @Author: zhoucheng
 * @Github: 
 * @Date: 2021-04-02 09:32:48
 * @LastEditors: zhoucheng
-->
<template>
  <div class='mainBody'>
    <el-row class="lineOne">
      <el-date-picker v-model="orderCreateTime"
                      type="daterange"
                      align="right"
                      unlink-panels
                      value-format="yyyy-MM-dd"
                      range-separator="-"
                      start-placeholder="开始日期"
                      end-placeholder="结束日期">
      </el-date-picker>
    </el-row>
    <el-row class="lineTwo">
      <el-row class="lineTwoCol">
        <el-row class="lineTwoCol-left">
          <el-row class="lineTwoCol-leftOne">停车收入（元）</el-row>
          <el-row class="lineTwoCol-leftTwo">{{Number(topFormList.receivedMoneyAmount/100).toFixed(2)}}</el-row>
        </el-row>
        <el-row class="lineTwoCol-right">
          <img src="@/assets/operationsManagement/money.png"
               alt="">
        </el-row>
      </el-row>
      <el-row class="lineTwoColRight">
        <el-row class="lineTwoColRight-left">
          <el-row class="lineTwoColRight-leftOne">订单总量（单）</el-row>
          <el-row class="lineTwoColRight-leftTwo">{{topFormList.orderNum}}</el-row>
        </el-row>
        <el-row class="lineTwoColRight-right">
          <img src="@/assets/operationsManagement/people.png"
               alt="">
        </el-row>
      </el-row>
      <el-row class="lineTwoColRight">
        <el-row class="lineTwoColRight-left">
          <el-row class="lineTwoColRight-leftOne">车行道运营泊位（个）</el-row>
          <el-row class="lineTwoColRight-leftTwo">{{topFormList.berths}}</el-row>
        </el-row>
        <el-row class="lineTwoColRight-right">
          <img src="@/assets/operationsManagement/load.png"
               alt="">
        </el-row>
      </el-row>
    </el-row>
    <el-row class="lineThree">
      <el-row class="lineThreeCol">
        <el-row class="lineThreeTitle">
          <el-row class="lineThreeTitleLeft">各街道停车场收入金额统计TOP10</el-row>
          <el-row class="lineThreeTitleRight">详情</el-row>
        </el-row>
        <el-row class="lineThreeCharts"
                id="middleLeftCharts"
                :option="middleLeftChartsOptions">
        </el-row>
      </el-row>
      <el-row class="lineThreeColRight">
        <el-row class="lineThreeTitle">
          <el-row class="lineThreeTitleLeft">收入第一各路段停车场收入金额统计TOP10</el-row>
          <el-row class="lineThreeTitleRight">详情</el-row>
        </el-row>
        <el-row class="lineThreeCharts"
                id="middleMiddleCharts"
                :option="middleMiddleChartsOptions">
        </el-row>
      </el-row>
      <el-row class="lineThreeColRight">
        <el-row class="lineThreeTitle">
          <el-row class="lineThreeTitleLeft">收入第一各停车场收入金额统计TOP10</el-row>
          <el-row class="lineThreeTitleRight">详情</el-row>
        </el-row>
        <el-row class="lineThreeCharts"
                id="middleRightCharts"
                :option="middleRightChartsOptions">
        </el-row>
      </el-row>
    </el-row>
    <el-row class="lineFour">
      <el-row class="lineFour-left">
        <el-row class="lineFour-leftOne">
          <el-row class="lineFourTitleLeft">街道停车收入TOP5时段分析</el-row>
          <el-row class="lineFourTitleRight">详情</el-row>
        </el-row>
        <el-row class="lineFour-leftTwo"
                id="bottomLeftCharts"
                :option="bottomLeftChartsOptions">
        </el-row>
      </el-row>
      <el-row class="lineFour-right">
        <el-row class="lineFour-rightOne">渠道分布</el-row>
        <el-row class="lineFour-rightTwo"
                id="bottomRightCharts"
                :option="bottomRihgtChartsOptions">
        </el-row>
      </el-row>
    </el-row>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import * as echarts from 'echarts';
import { mapState } from 'vuex'
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data () {
    //这里存放数据
    return {
      // 图表
      colorJson: {
        dark: {
          labelColor: "#ffffff"
        },
        light: {
          labelColor: "#000000"
        }
      },

      orderCreateTime: [],
      current: [],

      topFormList: {},

      middleLeftCharts: "",
      middleLeftChartsOptions: {},

      middleMiddleCharts: "",
      middleMiddleChartsOptions: {},

      middleRightCharts: "",
      middleRightChartsOptions: {},

      bottomLeftCharts: "",
      bottomLeftChartsOptions: {},

      bottomRightCharts: "",
      bottomRihgtChartsOptions: {},
    }
  },
  //监听属性 类似于data概念
  computed: {
    ...mapState({
      theme: (state) => state.app.theme,
    })
  },
  //监控data中的数据变化
  watch: {
    theme () {
      this.initCharts()
    },
    orderCreateTime (val) {
      if (val) {
        this.queryTopList()
        this.initCharts()
      }
    }
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created () { },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
    this.queryCurrentDay()
    this.queryTopList()
    // this.initCharts()
  },
  beforeCreate () { }, //生命周期 - 创建之前
  beforeMount () { }, //生命周期 - 挂载之前
  beforeUpdate () { }, //生命周期 - 更新之前
  updated () { }, //生命周期 - 更新之后
  beforeDestroy () { }, //生命周期 - 销毁之前
  destroyed () { }, //生命周期 - 销毁完成
  activated () { }, //如果页面有keep-alive缓存功能，这个函数会触发
  //方法集合
  methods: {
    // 获取当天时间
    queryCurrentDay () {
      let day = new Date()
      let a = day.getFullYear()
      let b = day.getMonth() + 1
      let c = day.getDate()
      if (JSON.stringify(b).length === 1) {
        b = "0" + b
      }
      if ((JSON.stringify(c).length === 1)) {
        c = "0" + c
      }
      this.current[0] = a + "-" + b + "-" + c
      this.current[1] = a + "-" + b + "-" + c
      if (this.orderCreateTime.length == 0) {
        this.orderCreateTime = this.current
      }
    },
    queryTopList () {
      let info = {
        parkTypeCode: "1",
        start: this.orderCreateTime[0],
        end: this.orderCreateTime[1]
      }
      this.$operationsManagement.revenueAnalysis(info).then(res => {
        this.topFormList = res.resultEntity
      })
    },
    initCharts () {
      this.queryMiddleLeftCharts()
      this.queryMiddleMiddleCharts()
      this.queryMiddleRightCharts()
      this.queryBottomLeftCharts()
      this.queryBottomRightCharts()
    },
    queryMiddleLeftCharts () {
      let labelColor = this.colorJson[this.theme].labelColor
      let myChart = echarts.init(document.getElementById('middleLeftCharts'));
      let yData = []
      let seriesData = []
      let info = {
        parkTypeCode: "1",
        start: this.orderCreateTime[0],
        end: this.orderCreateTime[1]
      }
      this.$operationsManagement.streetReceivedMoney(info).then(res => {
        res.resultEntity.forEach(item => {
          if (item.number !== 0) {
            yData.push(item.itemName)
            seriesData.push(Number(item.number / 100))
          }
        })
        myChart.setOption({
          backgroundColor: "",
          grid: {
            top: '1%',
            left: '15%',
            right: '5%',
            bottom: '10%',
          },
          xAxis: {
            type: 'value',
            axisLabel: {
              textStyle: {
                color: labelColor,
                fontSize: 12
              }
            },
            axisLine: {
              show: false
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: 'rgba(50, 59, 82, 1)',
              }
            },
            axisTick: {
              show: false
            },
          },
          yAxis: {
            type: 'category',
            data: yData,
            axisLabel: {
              textStyle: {
                color: labelColor,
                fontSize: 12
              }
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: '#2c4054'
              }
            },
            splitLine: {
              show: false
            },
            axisTick: {
              show: true
            },
          },
          tooltip: {},
          series: [{
            type: 'bar',
            name: "",
            data: seriesData,
            barWidth: 15,
            itemStyle: {
              normal: {
                color: 'rgba(8, 246, 228, 1)',
              }
            },
          }]
        })
      })
    },
    queryMiddleMiddleCharts () {
      let labelColor = this.colorJson[this.theme].labelColor
      let myChart = echarts.init(document.getElementById('middleMiddleCharts'));
      let yData = []
      let seriesData = []
      let info = {
        parkTypeCode: "1",
        start: this.orderCreateTime[0],
        end: this.orderCreateTime[1]
      }
      this.$operationsManagement.roadReceivedMoney(info).then(res => {
        res.resultEntity.forEach(item => {
          if (item.number !== 0) {
            yData.push(item.itemName)
            seriesData.push(Number(item.number / 100))
          }
        })
        myChart.setOption({
          backgroundColor: "",
          grid: {
            top: '1%',
            left: '15%',
            right: '5%',
            bottom: '10%',
          },
          xAxis: {
            type: 'value',
            axisLabel: {
              textStyle: {
                color: labelColor,
                fontSize: 12
              }
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: 'rgba(50, 59, 82, 1)',
              }
            },
            axisTick: {
              show: false
            },
            axisLine: {
              show: false
            },
          },
          yAxis: {
            type: 'category',
            data: yData,
            axisLabel: {
              textStyle: {
                color: labelColor,
                fontSize: 12
              }
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: '#2c4054'
              }
            },
            splitLine: {
              show: false
            },
            axisTick: {
              show: true
            },
          },
          tooltip: {},
          series: [{
            type: 'bar',
            name: "",
            data: seriesData,
            barWidth: 15,
            itemStyle: {
              normal: {
                label: {
                  show: false, //开启显示
                },
                color: 'rgba(178, 221, 157, 1)',
              }
            }
          }]
        })
      })
    },
    queryMiddleRightCharts () {
      let labelColor = this.colorJson[this.theme].labelColor
      let myChart = echarts.init(document.getElementById('middleRightCharts'));
      let yData = []
      let seriesData = []
      let info = {
        parkTypeCode: "1",
        start: this.orderCreateTime[0],
        end: this.orderCreateTime[1]
      }
      this.$operationsManagement.parkReceivedMoney(info).then(res => {
        res.resultEntity.forEach(item => {
          if (item.number !== 0) {
            yData.push(item.itemName)
            seriesData.push(Number(item.number / 100))
          }
        })
        myChart.setOption({
          backgroundColor: "",
          grid: {
            top: '1%',
            left: '30%',
            right: '5%',
            bottom: '10%',
          },
          xAxis: {
            type: 'value',
            axisLabel: {
              textStyle: {
                color: labelColor,
                fontSize: 12
              }
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: 'rgba(50, 59, 82, 1)',
              }
            },
            axisTick: {
              show: false
            },
            axisLine: {
              show: false
            },
          },
          yAxis: {
            type: 'category',
            data: yData,
            axisLabel: {
              textStyle: {
                color: labelColor,
                fontSize: 12
              }
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: '#2c4054'
              }
            },
            splitLine: {
              show: false
            },
            axisTick: {
              show: true
            }
          },
          tooltip: {},
          series: [{
            type: 'bar',
            name: "",
            data: seriesData,
            barWidth: 15,
            itemStyle: {
              normal: {
                label: {
                  show: false, //开启显示
                },
                color: 'rgba(123, 86, 241, 1)',
              }
            }
          }]
        })
      })
    },
    queryBottomLeftCharts () {
      let labelColor = this.colorJson[this.theme].labelColor
      let myChart = echarts.init(document.getElementById('bottomLeftCharts'));
      // let xLable = ['2021/02/01']
      // let yLable1 = ["30"]
      // let legendData = [{ name: '龙湖街道' }]
      let colorList = ['rgba(234, 170, 255, 1)', 'rgba(13, 130, 216, 1)', 'rgba(209, 113, 255, 1)', 'rgba(8, 246, 228, 1)', 'rgba(123, 86, 241, 1)']
      let seriesData = []
      let xLable = []
      let yLable = []
      let legendData = []
      let info = {
        parkTypeCode: "1",
        start: this.orderCreateTime[0],
        end: this.orderCreateTime[1]
      }
      this.$operationsManagement.streetReceivedMoneyLineChart(info).then(res => {
        res.resultEntity.forEach((item, index) => {
          legendData.push({ name: item.itemName })
          if (item.dataList.length != 0) {
            item.dataList.forEach(itemIn => {
              if (itemIn.number !== 0) {
                xLable.push(itemIn.itemName)
                yLable.push(Number(itemIn.number) / 100)
              }
            })
            seriesData.push({
              name: item.itemName,
              type: 'line',
              symbol: 'circle',
              showAllSymbol: true,
              symbolSize: 0,
              smooth: true,
              lineStyle: {
                normal: {
                  width: 2,
                  color: colorList[index], // 线条颜色
                },
              },
              itemStyle: {
                color: colorList[index],
                borderWidth: 2
              },
              tooltip: {
                show: true
              },
              data: yLable
            })
          }
        })
        myChart.setOption({
          xAxis: [{
            type: 'category',
            boundaryGap: false,
            data: xLable,
            axisLabel: {
              textStyle: {
                padding: 10,
                color: labelColor,
                fontSize: 12
              },
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: 'rgba(21, 46, 84, 1)'
              },
            },
            splitLine: {
              show: false,
              lineStyle: {
                color: ''
              },
            },
            axisTick: {
              show: false,
            },
          }],
          yAxis: [{
            name: '',
            min: 0,
            axisLabel: {
              show: true,
              textStyle: {
                padding: 10,
                color: labelColor,
                fontSize: 12
              }
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: 'rgba(21, 46, 84, 1)'
              },
            },
            axisLine: {
              show: false,
              lineStyle: {
                color: ""
              }
            },
            axisTick: {
              show: false,
            },
          }],
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              lineStyle: {
                color: {
                  type: 'linear',
                  x: 0,
                  y: 0,
                  x2: 0,
                  y2: 1,
                  colorStops: [{
                    offset: 0,
                    color: 'rgba(87, 224, 182, 0)' // 0% 处的颜色
                  }, {
                    offset: 0.5,
                    color: 'rgba(87, 224, 182, 1)' // 100% 处的颜色
                  }, {
                    offset: 1,
                    color: 'rgba(87, 224, 182, 0)' // 100% 处的颜色
                  }],
                  global: false // 缺省为 false
                }
              },
            }
          },
          grid: {
            top: '10%',
            bottom: '12%',
            left: '6%',
            right: '4%',
          },
          legend: {
            data: legendData,
            align: "left",
            top: '0%',
            right: '5%',
            type: 'plain',
            textStyle: {
              color: labelColor,
              fontSize: 12
            },
            icon: 'circle',
            itemGap: 25,
            itemWidth: 10
          },
          series: seriesData
        })
      })
    },
    queryBottomRightCharts () {
      let labelColor = this.colorJson[this.theme].labelColor
      let myChart = echarts.init(document.getElementById('bottomRightCharts'));
      let colorList = [
        {
          type: 'linear',
          colorStops: [
            {
              offset: 1,
              color: 'rgba(50, 111, 220, 1)',
            },
          ],
        },
        {
          type: 'linear',
          colorStops: [
            {
              offset: 1,
              color: 'rgba(8, 246, 228, 1)',
            },
          ],
        },
        {
          type: 'linear',
          colorStops: [

            {
              offset: 1,
              color: 'rgba(178, 221, 157, 1)',
            },
          ],
        },
        {
          type: 'linear',
          colorStops: [

            {
              offset: 1,
              color: 'rgba(123, 86, 241, 1)',
            },
          ],
        },
      ];
      let color = ['#FF8700', '#ffc300', '#00e473', '#009DFF'];
      let legendData = []
      let nameList = [];
      let valueList = [];
      let chartData = [];
      let arrName = [];
      let arrValue = [];
      let sum = 0;
      let pieSeries = []
      let lineYAxis = [];
      let info = {
        parkTypeCode: "1",
        start: this.orderCreateTime[0],
        end: this.orderCreateTime[1]
      }
      this.$operationsManagement.paymentDistribution(info).then(res => {
        res.resultEntity.forEach(item => {
          if (item.number !== 0) {
            legendData.push({ name: item.itemName })
            nameList.push(item.itemName)
            valueList.push(Number(item.number) / 100)
          }
        })

        nameList.map((item, idx) => {
          chartData.push({
            name: item,
            value: valueList[idx],
          });
        });
        // 数据处理
        chartData.forEach((v) => {
          arrName.push(v.name);
          arrValue.push(v.value);
          sum = sum + v.value;
        });
        function getRadius (i) {
          return [67 - i * 12 + '%', 59 - i * 12 + '%'];
        }
        // 图表option整理
        chartData.forEach((v, i) => {
          pieSeries.push({
            name: '',
            type: 'pie',
            clockWise: true,
            hoverAnimation: false,
            radius: getRadius(i),
            center: ['50%', '55%'],
            itemStyle: {
              color: colorList[i],
            },
            label: {
              show: false,
            },
            data: [
              {
                value: v.value,
                name: v.name,
              },
              {
                value: sum - v.value,
                name: '',
                itemStyle: {
                  color: 'rgba(0,0,0,0)',
                },
              },
            ],
          });
          v.percent = ((v.value / sum) * 100).toFixed(2);
          lineYAxis.push({
            value: i,
            textStyle: {
              rich: {
                circle: {
                  color: color[i],
                },
              },
            },
          });
        });
        myChart.setOption({
          tooltip: {
            backgroundColor: '',
            borderWidth: 0,
            trigger: 'item',
          },
          color: color,
          grid: {
            top: '15%',
            bottom: '50%',
            left: '50%',
            containLabel: false,
          },
          yAxis: [
            {
              type: 'category',
              inverse: true,
              axisLine: {
                show: false,
              },
              axisTick: {
                show: false,
              },
              axisLabel: {
                formatter: function (params) {
                  let item = chartData[params];
                  return '{name|' + item.name + '}';
                },
                interval: 0,
                inside: false,
                textStyle: {
                  color: labelColor,
                  fontSize: 12,
                  rich: {
                    name: {
                      color: labelColor,
                      fontSize: 12,
                    },
                  },
                },
                show: true,
              },
              data: lineYAxis,
            },
          ],
          legend: {
            data: legendData,
            orient: 'vertical',
            align: "left",
            top: '10%',
            left: '5%',
            type: 'plain',
            textStyle: {
              color: labelColor,
              fontSize: 12
            },
            icon: 'circle',
            itemGap: 25,
            itemWidth: 10,
          },
          xAxis: [
            {
              show: false,
            },
          ],
          series: pieSeries,
        })
      })
    }
  },
}
</script>
<style lang='scss' scoped>
//@import url(); 引入公共css类
.mainBody {
  width: 100%;
  height: 100%;
  overflow: hidden;
  .lineOne {
    width: 240px;
    height: 32px;
  }
  .lineTwo {
    width: 100%;
    height: 19%;
    margin-top: 0.5%;
    display: flex;
    .lineTwoCol {
      @include background('chartsBack');
      width: 33.3%;
      height: 100%;
      display: flex;
      .lineTwoCol-left {
        width: 60%;
        height: 100%;
        padding-left: 6.5%;
        padding-top: 5%;
        .lineTwoCol-leftOne {
          color: rgba(112, 174, 232, 1);
          width: 100%;
          height: 22px;
          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          line-height: 22px;
        }
        .lineTwoCol-leftTwo {
          color: #ffffff;
          width: 100%;
          height: 39px;
          margin-top: 3%;
          font-size: 36px;
          font-family: HelveticaNeue-Medium, HelveticaNeue;
          font-weight: 500;
          line-height: 44px;
        }
      }
      .lineTwoCol-right {
        width: 40%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    .lineTwoColRight {
      @include background('chartsBack');
      width: 33%;
      height: 100%;
      display: flex;
      margin-left: 0.5%;
      .lineTwoColRight-left {
        width: 60%;
        height: 100%;
        padding-left: 6.5%;
        padding-top: 5%;
        .lineTwoColRight-leftOne {
          color: rgba(112, 174, 232, 1);
          width: 100%;
          height: 22px;
          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          line-height: 22px;
        }
        .lineTwoColRight-leftTwo {
          color: #ffffff;
          width: 100%;
          height: 39px;
          margin-top: 3%;
          font-size: 36px;
          font-family: HelveticaNeue-Medium, HelveticaNeue;
          font-weight: 500;
          line-height: 44px;
        }
      }
      .lineTwoColRight-right {
        width: 40%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
  .lineThree {
    width: 100%;
    height: 37%;
    margin-top: 0.5%;
    display: flex;
    .lineThreeCol {
      @include background('chartsBack');
      width: 33.3%;
      height: 100%;
      .lineThreeTitle {
        width: 100%;
        height: 30px;
        display: flex;
        align-items: center;
        position: relative;
        padding-top: 20px;
        .lineThreeTitleLeft {
          color: #ffffff;
          font-size: 16px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          line-height: 22px;
          padding-left: 20px;
        }
        .lineThreeTitleRight {
          color: #12afff;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          line-height: 20px;
          position: absolute;
          float: right;
          right: 20px;
        }
      }
      .lineThreeCharts {
        width: 100%;
        height: 87%;
        margin-top: 10px;
      }
    }
    .lineThreeColRight {
      @include background('chartsBack');
      width: 33%;
      height: 100%;
      margin-left: 0.5%;
      .lineThreeTitle {
        width: 100%;
        height: 30px;
        display: flex;
        align-items: center;
        position: relative;
        padding-top: 20px;
        .lineThreeTitleLeft {
          color: #ffffff;
          font-size: 16px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          line-height: 22px;
          padding-left: 20px;
        }
        .lineThreeTitleRight {
          color: #12afff;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          line-height: 20px;
          position: absolute;
          float: right;
          right: 20px;
        }
      }
      .lineThreeCharts {
        width: 100%;
        height: 87%;
        margin-top: 10px;
      }
    }
  }
  .lineFour {
    width: 100%;
    height: 37%;
    margin-top: 0.5%;
    display: flex;
    .lineFour-left {
      width: 66.5%;
      height: 100%;
      @include background('chartsBack');
      .lineFour-leftOne {
        width: 100%;
        height: 30px;
        display: flex;
        align-items: center;
        position: relative;
        padding-top: 20px;
        .lineFourTitleLeft {
          color: #ffffff;
          font-size: 16px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          line-height: 22px;
          padding-left: 20px;
        }
        .lineFourTitleRight {
          color: #12afff;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          line-height: 20px;
          position: absolute;
          float: right;
          right: 20px;
        }
      }
      .lineFour-leftTwo {
        width: 100%;
        height: 87%;
        margin-top: 10px;
      }
    }
    .lineFour-right {
      width: 32.7%;
      height: 100%;
      margin-left: 0.7%;
      @include background('chartsBack');
      .lineFour-rightOne {
        width: 100%;
        height: 30px;
        display: flex;
        align-items: center;
        position: relative;
        padding-top: 20px;
        padding-left: 20px;
        color: #ffffff;
      }
      .lineFour-rightTwo {
        width: 100%;
        height: 87%;
        margin-top: 10px;
      }
    }
  }
}
</style>